<template>
    <div>
        <Report module="Manutenção" name="Produto" urlGet="/api/v1/maintenance/report/report-product-order-service"
            :header="header" :filters="filters" />
    </div>
</template>

<script>

import Report from "@nixweb/nixloc-ui/src/component/report/Report";

export default {
    name: "ReportOrderService",
    components: {
        Report
    },
    data() {
        return {
            header: [
                {
                    title: "Número",
                    field: "number",
                    fieldOrderBy: "Number",
                    show: true,
                },
                {
                    title: "Data Cadastro",
                    field: "registeredDateStr",
                    fieldOrderBy: "RegisteredDate",
                    show: true,
                },
                {
                    title: "Identificação",
                    field: "identification",
                    fieldOrderBy: "Identification",
                    show: false,
                },
                {
                    title: "Produto",
                    field: "productName",
                    fieldOrderBy: "ProductName",
                    show: true,
                },
                {
                    title: "Patrimonio",
                    field: "barCode",
                    fieldOrderBy: "BarCode",
                    show: true,
                },
                {
                    title: "ST Orçamento",
                    field: "statusName",
                    fieldOrderBy: "StatusName",
                    show: true,
                },
                {
                    title: "ST Manutenção",
                    field: "returnedName",
                    fieldOrderBy: "ReturnedName",
                    show: true,
                },
                {
                    title: "Data Início",
                    field: "dateStartStr",
                    fieldOrderBy: "DateStartStr",
                    show: true,
                },
                {
                    title: "Data Fim",
                    field: "dateEndStr",
                    fieldOrderBy: "DateEndStr",
                    show: true,
                },
                {
                    title: "Cliente",
                    field: "customerName",
                    fieldOrderBy: "CustomerName",
                    show: false,
                },
                {
                    title: "Fornecedor",
                    field: "supplierName",
                    fieldOrderBy: "SupplierName",
                    show: false,
                },
                {
                    title: "Tipo",
                    field: "typeMaintenanceName",
                    fieldOrderBy: "TypeMaintenanceName",
                    show: false,
                },
                {
                    title: "R$ Cliente",
                    field: "valueCustomerStr",
                    fieldOrderBy: "ValueCustomer",
                    show: true,
                },
                {
                    title: "R$ Fornecedor",
                    field: "valueSupplierStr",
                    fieldOrderBy: "ValueSupplier",
                    show: true,
                },
            ],
            filters: [
                {
                    type: "date",
                    title: "Data Cadastro",
                    field: "RegisteredDate",
                    value: "",
                    required: true,
                },
                {
                    type: "date",
                    title: "Data Início",
                    field: "DateStart",
                    value: "",
                },
                {
                    type: "date",
                    title: "Data Fim",
                    field: "DateEnd",
                    value: "",
                },
                {
                    type: "multiOptions",
                    title: "Tipo Produto",
                    field: "TypeProductName",
                    url: "/api/v1/stock/type-product/select-all",
                    value: "",
                },
                {
                    type: "multiOptions",
                    title: "Produto",
                    field: "ProductName",
                    url: "/api/v1/stock/product/select-all",
                    value: "",
                },
                {
                    type: "multiOptions",
                    title: "Cliente",
                    field: "CustomerName",
                    url: "/api/v1/crm/customer/select-all-without-document",
                    propsParams: { isCustomer: true, isSupplier: false },
                    value: "",
                },
                {
                    type: "multiOptions",
                    title: "Fornecedor",
                    field: "SupplierName",
                    url: "/api/v1/crm/customer/select-all-without-document",
                    propsParams: { isCustomer: false, isSupplier: true },
                    value: "",
                },
                {
                    type: "options",
                    title: "Tipo",
                    field: "TypeMaintenanceName",
                    options: [
                        { text: "Corretiva", value: "Corretiva" },
                        { text: "Preventiva", value: "Preventiva" },
                    ],
                    value: [],
                },
                {
                    type: "options",
                    title: "ST Orçamento",
                    field: "StatusName",
                    options: [
                        { text: "Aprovada", value: "Aprovada" },
                        { text: "Orçamento", value: "Orçamento" },
                        { text: "Reprovada", value: "Reprovada" },
                    ],
                    value: [],
                },
                {
                    type: "options",
                    title: "ST Manutenção",
                    field: "ReturnedName",
                    options: [
                        { text: "Pendente", value: "Pendente" },
                        { text: "Retornado", value: "Retornado" },
                    ],
                    value: [],
                },
                {
                    type: "decimal",
                    title: "Valor Cliente",
                    field: "ValueCustomer",
                    valueStart: 0,
                    valueEnd: 0,
                },
                {
                    type: "decimal",
                    title: "Valor Fornecedor",
                    field: "ValueSupplier",
                    valueStart: 0,
                    valueEnd: 0,
                },
            ],
        }
    }
}


</script>